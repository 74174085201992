import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
// import proConfig from '../public/global/proConfig'


Vue.use(ElementUI)
Vue.use(VueAxios, axios)

Vue.prototype.$ProConfig = window.pc_global
// console.log(window.pc_global);

const isRelease = true
if (isRelease) {
  axios.defaults.baseURL = Vue.prototype.$ProConfig.APIADD
} else {
  axios.defaults.baseURL = '/api'
}

if (localStorage.getItem('QCToken')) {
  axios.defaults.headers.common['Author'] = localStorage.getItem('QCToken');
}

// 添加响应拦截器
axios.interceptors.response.use(function (response) {
  // 对响应数据做点什么
  return response;
}, function (error) {
  // 对响应错误做点什么
  // const code = error.response.data.code
  // let msg = error.response.data.message;
  // if(code == '2001') {
  //   msg = '当前城市没有机构'
  // }
  // alert(msg)
  return Promise.reject(error);
});

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')
