<template>
  <div class="footer">
    <div class="contactus">
      <div>
        <span class="linkp" @click="onclick"> 京ICP备16058577号-3 </span>
      </div>
      <div><span>数据上传：</span><span class="linkp">ncqcr_rg@163.com</span></div>
      <div><span>问题反馈：</span><span class="linkp">ncqcr_qc@163.com</span></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CusFooter",
  data() {
    return {
      url: "https://beian.miit.gov.cn",
    };
  },
  methods: {
    onclick() {
      window.open(this.url);
    },
  },
};
</script>

<style scoped>
.footer {
  width: 100%;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}
.linkp {
  cursor: pointer;
  font-size: 14px;
}
.linkp:hover {
  color: #409eff;
}
.contactus {
  display: flex;
  justify-content: space-around;
  justify-items: center;
  font-size: 14px;
  width: 70%;
  margin: 0 auto;
}
</style>